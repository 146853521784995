<template>
    <div class="home">
      
      <el-menu :default-active="formInline.status" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="">全部</el-menu-item>
        <el-menu-item index="1">待审核</el-menu-item>
        <el-menu-item index="2">审核通过</el-menu-item>
        <el-menu-item index="3">审核未通过</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
<el-form-item label="审核时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="提交时间">
                <el-date-picker
                    v-model="timevalue2"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

            <el-form-item style="font-weight: bold;" label="关键字">
              <el-input style="width:300px;" v-model="formInline.query" placeholder="手机号、用户编号、审核员、审核订单编号"></el-input>
              <el-button style="margin-left:20px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
            
      </el-form> 
      <div style="text-align: left;margin-left:20px;" @change="onSubmit('CHA')" >
                <el-radio v-model="formInline.sort" label="asc" >升序</el-radio>
                <el-radio v-model="formInline.sort" label="desc" >降序</el-radio>
            </div>
      <div class="pt20"></div>

      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
        
      <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50"></el-table-column>

        <el-table-column
          prop="no"
          label="审核订单编号">
        </el-table-column>

        <el-table-column
          prop="created_at"
          label="提交时间">
        </el-table-column>
        
        <el-table-column
          prop="phone"
          label="账户手机号">
        </el-table-column>

        <el-table-column
          prop="user.uid"
          label="用户编号">
        </el-table-column>
        
        <el-table-column
          prop="status_text" 
          label="审核状态">
        </el-table-column>

        <el-table-column
          prop="operator_name"
          label="审核员">
        </el-table-column>
        
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1 && scope.row.status != 3" @click="goedit(scope.row)" size="mini" type="info" plain>审核</el-button>
        <el-button v-if="detail == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      edit:location.edit,
      detail:location.detail,
      tableData: [],
      total:0,
      per_page: 0,
      current_page:1,
      formInline: {
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
      },
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     initdata:{
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  watch:{
  },
  methods: { 
    handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.e_sdate = '';
          _this.formInline.e_edate = '';
          _this.formInline.t_sdate = '';
          _this.formInline.t_edate = '';
          if(_this.timevalue){
            _this.formInline.e_sdate = formatDate(_this.timevalue[0]);
            _this.formInline.e_edate = formatDate(_this.timevalue[1]);
          }
          if(_this.timevalue2){
            _this.formInline.t_sdate = formatDate(_this.timevalue2[0]);
            _this.formInline.t_edate = formatDate(_this.timevalue2[1]);
          }
        axios.get(config.commentExamine,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/comment/commentedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/comment/commentedit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/comment/commentedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/comment/commentedit');
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        if(this.selectedNum != 1){
          this.$message.error('不支持批量');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.commentlistChangeStatus, {org_id:this.ghs[0],status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.init();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.org_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },
    init(){
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        let _this = this;
        axios.get(config.commentExamine,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>